import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { ButtonConfirmed } from 'components/Button'
import Column, { AutoColumn } from 'components/Column'
import SwapCurrencyInputPanel from 'components/CurrencyInputPanel/SwapCurrencyInputPanel'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import { StyledToIcon } from 'components/Icons/StyledIcon'
import Row, { RowBetween } from 'components/Row'
import { Input } from 'components/Settings/Input'
import {
  ConfirmationModalContent2222,
  TransactionConfirmationModal22222,
} from 'components/TransactionConfirmationModal'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useCurrency } from 'hooks/Tokens'
import { useOrderBookContract } from 'hooks/useContract'
import { useInputAmount } from 'hooks/useInputAmount'
import { useCallback, useMemo, useState } from 'react'
import { useTradeSWAPState } from 'state/positionSwap/hooks'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { BREAKPOINTS, ThemedText } from 'theme'
import { BN, fromSqrt96Wei, toSqrt96 } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { handlerError } from 'utils/formatError'

import { useTwapPrice } from '../Hooks'
import { EditSwapSection } from './EditPositionModal'

interface EditLimitModalProps {
  isOpen: boolean
  onDismiss: () => void
  limit: any
}

const EditWrapper = styled.div`
  width: 100%;
`

const EditFeeBox = styled(Column)`
  width: 100%;
  gap: 8px;
  align-items: flex-start;
`

const StyledInput = styled(Input)`
  text-align: left;
  font-size: 36px;
  font-weight: 700;
  font-family: 'TTHoves';
  line-height: 44px;
  font-variant: small-caps;
`

export default function EditLimitModal({ isOpen, onDismiss, limit }: EditLimitModalProps) {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)

  const currency0 = useCurrency(limit.token0)
  const currency1 = useCurrency(limit.token1)

  const { account } = useActiveChainId()

  const twapPrice = useTwapPrice(limit.spotpool)

  const [triggerPrice, setTriggerPrice] = useState<any>(
    fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed()
  )

  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const { isToken0 } = useTradeSWAPState()

  const addTransaction = useTransactionAdder()
  const orderBookContract = useOrderBookContract()

  const onModalDismiss = useCallback(() => {
    onDismiss()
    setTxHash('')
    setTxError('')
    setAttemptingTxn(false)
  }, [onDismiss])

  const tokenCurrency = limit.long0 ? currency0 : currency1

  const { inputAmount: amount, handleUserInput: setAmount, isInputValid, inputError } = useInputAmount(tokenCurrency)
  const handleAmountChange = useCallback(
    (amount: string) => {
      setAmount(amount)
    },
    [setAmount]
  )

  const handlePriceChange = (e: any) => {
    setTriggerPrice(e.target.value)
  }

  const handelEdit = useCallback(async () => {
    setAttemptingTxn(true)
    if (
      !orderBookContract ||
      !amount ||
      !amount.amount?.numerator.toString() ||
      !triggerPrice ||
      !limit.key ||
      !twapPrice ||
      !currency0 ||
      !currency1
    )
      return

    const current = BN(amount.amount.numerator.toString()).minus(limit.executionFee.toString())

    let price = BN(triggerPrice)
      .div(BN(10).pow(currency0.decimals))
      .multipliedBy(BN(10).pow(currency1.decimals))
      .toString()

    if (!isToken0) {
      price = BN(1).div(price).toFixed()
    }

    const triggerAboveThreshold = BN(price).gt(fromSqrt96Wei(twapPrice.toString()))

    try {
      const response = await orderBookContract.updateIncreaseOrder(
        limit.key,
        '0',
        '0',
        current.toFixed(),
        toSqrt96(price).toFixed(0),
        triggerAboveThreshold
      )

      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.EDIT,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('edit error', error)
    }
  }, [
    orderBookContract,
    amount,
    triggerPrice,
    limit.key,
    limit.executionFee,
    twapPrice,
    currency0,
    currency1,
    isToken0,
    addTransaction,
  ])

  const symbol = () => {
    if (isToken0) {
      return `${currency0?.symbol}/${currency1?.symbol}`
    } else {
      return `${currency1?.symbol}/${currency0?.symbol}`
    }
  }

  const currenttriggerPrice = useMemo(() => {
    if (isToken0) {
      return fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed()
    } else {
      return BN(1)
        .div(fromSqrt96Wei(limit.triggerPrice.toString(), currency0?.decimals, currency1?.decimals).toFixed())
        .toFixed()
    }
  }, [currency0?.decimals, currency1?.decimals, isToken0, limit.triggerPrice])

  const fromdatatriggerPrice = useMemo(() => {
    if (!triggerPrice) return '0'
    if (isToken0) {
      return triggerPrice
    } else {
      return BN(1).div(triggerPrice).toString()
    }
  }, [isToken0, triggerPrice])
  const modalContent = (
    <ConfirmationModalContent2222
      onDismiss={onModalDismiss}
      title={<Trans>Edit</Trans>}
      topContent={
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          gap={isSmallScreen ? '16px' : '24px'}
          mt="16px"
        >
          <EditWrapper>
            <AutoColumn gap="sm">
              <EditSwapSection>
                <EditFeeBox>
                  <ThemedText.TextSecondary fontSize={14}>Trigger Price</ThemedText.TextSecondary>
                  <RowBetween>
                    <StyledInput
                      type="number"
                      className="price-input"
                      defaultValue={triggerPrice}
                      value={triggerPrice}
                      onChange={handlePriceChange}
                      align="left"
                      placeholder="0"
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                    />
                    <Box display="flex" alignItems="center">
                      <DoubleCurrencyLogo
                        size={24}
                        margin
                        currency0={isToken0 ? currency1 ?? undefined : currency0 ?? undefined}
                        currency1={!isToken0 ? currency1 ?? undefined : currency0 ?? undefined}
                      />
                      <Column gap="xs" align="flex-start" ml="8px">
                        <ThemedText.TextPrimary fontWeight={500} fontSize={16}>
                          {symbol()}
                        </ThemedText.TextPrimary>
                      </Column>
                    </Box>
                  </RowBetween>
                </EditFeeBox>
              </EditSwapSection>
              <ThemedText.TextSecondary fontSize={14} mt="8px"></ThemedText.TextSecondary>
              <EditSwapSection>
                <SwapCurrencyInputPanel
                  label={<Trans>Execution Fees</Trans>}
                  disabled={false}
                  currency={tokenCurrency}
                  value={amount.value}
                  showMaxButton={true}
                  id="edit-limit-input"
                  onUserInput={handleAmountChange}
                  loading={false}
                  tradeValue="Execution Fees"
                  showMaxTab={true}
                  onMaxTab={handleAmountChange}
                />
              </EditSwapSection>
              <RowBetween gap="sm" mt="8px">
                <ThemedText.TextSecondary fontSize={14}>Trigger Price</ThemedText.TextSecondary>
                <ThemedText.TextPrimary fontWeight={500} className="" style={{ flex: 1, wordBreak: 'break-word' }}>
                  <Box display="flex" alignItems="center" gap="8px">
                    <>{countZeros(currenttriggerPrice)}</>
                    <StyledToIcon />
                    <>{countZeros(fromdatatriggerPrice)}</>
                  </Box>
                </ThemedText.TextPrimary>
              </RowBetween>
            </AutoColumn>
            <Row mt="16px">
              <ButtonConfirmed
                data-testid="confirm-trade-button"
                id="confirm-trade-button"
                mt="8px"
                onClick={handelEdit}
              >
                <ThemedText.HeadlineSmall color="accentTextLightPrimary">
                  <Trans>Submit</Trans>
                </ThemedText.HeadlineSmall>
              </ButtonConfirmed>
            </Row>
          </EditWrapper>
        </Box>
      }
    />
  )
  return (
    <TransactionConfirmationModal22222
      isOpen={isOpen}
      onDismiss={onModalDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      txError={txError}
      pendingText={pendingText}
      width="400px"
      reviewContent={modalContent}
    />
  )
}
