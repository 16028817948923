import 'rc-slider/assets/index.css'
import 'assets/css/animista.css'
import 'assets/css/rainbowkit.css'
import 'react-toastify/dist/ReactToastify.css'

import { useMediaQuery } from '@mui/material'
import { Trace } from 'analytics'
import Loader from 'components/Icons/LoadingSpinner'
import NavMenuTabs from 'components/NavBar/NavMenuTabs'
import Releases from 'components/Releases'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { useBag } from 'nft/hooks/useBag'
import { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { cssTransition, ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'
import { flexRowNoWrap } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'
import { getCurrentPageFromLocation } from 'utils/urlRoutes'

import ErrorBoundary from '../components/ErrorBoundary'
import NavBar from '../components/NavBar'
import Polling from '../components/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/components/DarkModeQueryParamReader'
import AddLiquidity from './AddLiquidity'
import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
import Airdrop from './Airdrop'
import Ranking from './Airdrop/Ranking'
import Gague from './Gague'
import Leaderboard from './Leaderboard'
import LeaderboardDetail from './Leaderboard/LeaderboardDetail'
import Pools from './Liquidity'
import Lock from './Lock'
import NotFound from './NotFound'
import OrderList from './OrderList'
import MyPools from './Pool'
import PositionPage from './Pool/PositionPage'
import Profile from './Profile'
import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import { RedirectPathToSwapOnly } from './Swap/redirects'
import Trade from './Trade'
import Vote from './Vote'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.navHeight}px 0px 5rem 0px;
  align-items: center;
  flex: 1;
`

const MobileBottomBar = styled.div`
  z-index: ${Z_INDEX.sticky};
  position: fixed;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100vw;
  min-height: 60px;
  justify-content: space-between;
  padding: 12px 16px;
  background: ${({ theme }) => theme.backgroundSurface};
  border-top: 1px solid ${({ theme }) => theme.backgroundOutline};

  @media screen and (max-width: ${({ theme }) => theme.breakpoint.xs}px) {
    padding: 12px 8px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    display: none;
  }
`

const HeaderWrapper = styled.div<{ transparent?: boolean }>`
  ${flexRowNoWrap};
  background-color: ${({ theme, transparent }) => !transparent && theme.backgroundModule};
  border-bottom: ${({ theme, transparent }) => !transparent && `1px solid ${theme.backgroundOutline}`};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.dropdown};
`

export default function App() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const isLoaded = useFeatureFlagsIsLoaded()

  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const [scrolledState, setScrolledState] = useState(false)

  const Zoom = cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
    appendPosition: false,
    collapse: true,
    collapseDuration: 200,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    setScrolledState(false)
  }, [pathname])

  useEffect(() => {
    const scrollListener = () => {
      setScrolledState(window.scrollY > 0)
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  const isBagExpanded = useBag((state) => state.bagExpanded)
  const isHeaderTransparent = !scrolledState && !isBagExpanded

  return (
    <ErrorBoundary>
      <ToastContainer
        // limit={1}
        draggable={false}
        // transition={Zoom}
        position="bottom-right"
        // closeButton={false}
        newestOnTop={false}
        pauseOnFocusLoss
        closeOnClick={false}
        // icon={false}
      />
      <DarkModeQueryParamReader />
      <Trace page={currentPage}>
        <HeaderWrapper transparent={isHeaderTransparent}>
          <NavBar blur={isHeaderTransparent} />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Polling />
          {!isSmallScreen && <Releases />}
          <Suspense fallback={<Loader />}>
            {isLoaded ? (
              <Routes>
                <Route path="/" element={<Swap />} />
                <Route path="perp" element={<Trade />} />
                <Route path="send" element={<RedirectPathToSwapOnly />} />
                <Route path="swap/" element={<Swap />} />
                <Route path="swap/:tokenId" element={<Swap />} />
                <Route path="lock" element={<Lock />} />
                <Route path="vote" element={<Vote />} />
                <Route path="pools" element={<Pools />} />

                {/* <Route path="points" element={<Airdrop />} />
                <Route path="ranking" element={<Ranking />} /> */}

                <Route path="profile" element={<Profile />} />
                <Route path="orderList" element={<OrderList />} />
                <Route path="leaderBoard" element={<Leaderboard />} />
                <Route path="leaderBoardDetail/:pool" element={<LeaderboardDetail />} />
                <Route path="gague" element={<Gague />} />
                <Route path="myPools" element={<MyPools />} />
                <Route path="myPools/:tokenId" element={<PositionPage />} />
                <Route path="add" element={<RedirectDuplicateTokenIds />}>
                  {/* this is workaround since react-router-dom v6 doesn't support optional parameters any more */}
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                </Route>

                <Route path="increase" element={<AddLiquidity />}>
                  <Route path=":currencyIdA" />
                  <Route path=":currencyIdA/:currencyIdB" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount" />
                  <Route path=":currencyIdA/:currencyIdB/:feeAmount/:tokenId" />
                </Route>

                <Route path="remove/:tokenId" element={<RemoveLiquidityV3 />} />

                <Route path="*" element={<Navigate to="/not-found" replace />} />
                <Route path="/not-found" element={<NotFound />} />
              </Routes>
            ) : (
              <Loader />
            )}
          </Suspense>
        </BodyWrapper>
        <MobileBottomBar>
          {/* <PageTabs /> */}
          <NavMenuTabs />
        </MobileBottomBar>
      </Trace>
    </ErrorBoundary>
  )
}
